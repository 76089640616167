export const USER_TITLE = "Users";
export const USER_PATH = "/";
export const USER_DETAIL_TITLE = "User Detail";
export const USER_DETAIL_PATH = "/users/:id";

export const JOB_TITLE = "Jobs";
export const JOB_PATH = "/jobs";

export const CONTACT_TITLE = "Contact Us";
export const CONTACT_PATH = "/contact";

export const LYRICS_TITLE = "Lyrics";
export const LYRICS_PATH = "/lyrics";
export const LYRICS_DETAILS_TITLE = "Lyrics";
export const LYRICS_DETAILS_PATH = "lyrics/:id";
