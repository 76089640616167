import styles from "../../styles/table.module.css";
import { ReactComponent as Archive } from "../../assets/archive.svg";
import { ReactComponent as UnArchive } from "../../assets/unarchive.svg";
import { ReactComponent as Trash } from "../../assets/trash.svg";
import { ReactComponent as Tick } from "../../assets/tick.svg";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive, faTrash } from "@fortawesome/free-solid-svg-icons";

type props = {
  head: { title: string }[];
  body: any[];
  onRowClick?: any;
  body_keys: string[];
  number?: boolean;
  action_list?: string[];
  action_handler?: any;
  onActionClick?: any;
  onRemarksSave?: any;
  handleDropdownUpdate?: any;
  dropdownValues?: any;
  page?: any;
};

const HandleRemarksPart = (props: any) => {
  const [editMode, setEditMode] = useState(false);
  const [remark, setRemark] = useState(
    props.data?.remarks
      ? props.data?.remarks
      : props.data?.remarksInput
      ? props.data?.remarksInput
      : ""
  );
  // const [re]

  return (
    <>
      {editMode ? (
        <div style={{ display: "flex", gap: "5px" }}>
          <textarea
            style={{
              width: "100%",
              height: "100px",
              padding: "5px",
              borderRadius: "10px",
              border: "1px solid grey",
            }}
            value={remark}
            onChange={(event) => {
              setRemark(event.target.value);
            }}
            onClick={(event: any) => {
              event.stopPropagation();
            }}
          />
          <div
            style={{
              flexShrink: 0,
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <button
              onClick={(event: any) => {
                setEditMode(false);
                props.onRemarksSave(props.data, remark);
                event.stopPropagation();
              }}
              style={{
                width: "35px",
                height: "35px",
                background: "green",
                border: "none",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <Tick style={{ color: "white" }} />
            </button>
            <button
              onClick={(event: any) => {
                setEditMode(false);
                setRemark(
                  props.data?.remarks
                    ? props.data?.remarks
                    : props.data?.remarksInput
                    ? props.data?.remarksInput
                    : ""
                );
                event.stopPropagation();
              }}
              style={{
                width: "35px",
                height: "35px",
                background: "red",
                border: "none",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <Cross style={{ fill: "white", height: "20px" }} />
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "3px",
            justifyContent: "center",
          }}
        >
          <div
            className={styles.remarks}
            onClick={(event: any) => {
              setEditMode(true);
              event.stopPropagation();
            }}
          >
            {remark ? <p>{remark} </p> : "＋ Add Remark"}
          </div>
          {remark ? (
            <span
              style={{
                display: "inline-block",
                transform: "rotateY(180deg)",
                cursor: "pointer",
              }}
              onClick={() => {
                setEditMode(true);
              }}
            >
              ✎
            </span>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

const HandleActionValue = ({ val, data, index, onActionClick }: any) => {
  let action_list = val.split(":")[1].split(",");
  let component_list = [];

  //checkForDelete

  if (action_list.indexOf("archive") !== -1) {
    if (data.status === "draft") {
      component_list.push(
        <abbr
          key={index + "-unarchive"}
          title="Unarchive this entry."
          data-name="unarchive"
        >
          <div
            data-name="unarchive"
            style={{
              display: "inline-flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <UnArchive
              data-name="unarchive"
              style={{ height: "28px", width: "30px" }}
              onClick={() => {
                onActionClick("unarchive", data?._id);
              }}
            />
          </div>
        </abbr>
      );
    }
    if (data.status === "live") {
      component_list.push(
        <abbr
          key={index + "-archive"}
          title="Archive this entry."
          data-name="archive"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            data-name="archive"
            style={{
              display: "inline-flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Archive
              data-name="archive"
              style={{ height: "28px", width: "30px" }}
              onClick={() => {
                onActionClick("archive", data?._id);
              }}
            />
          </div>
        </abbr>
      );
    }
  }

  if (action_list.indexOf("delete") !== -1) {
    component_list.push(
      <abbr
        key={index + "-delete"}
        title="delete"
        // data-name="delete"
        style={{
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {}}
      >
        <div
          style={{
            display: "inline-flex",
            cursor: "pointer",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faTrash}
            color={"red"}
            style={{ fontSize: "1.2rem", cursor: "pointer" }}
            onClick={() => {
              onActionClick("delete", data?._id);
            }}
          />
          {/* <Trash
            data-name="delete"
            style={{ height: "25px", width: "30px", fill: "red" }}
          /> */}
        </div>
      </abbr>
    );
  }

  return <div style={{ display: "flex", gap: "10px" }}>{component_list}</div>;
};

const Table = (props: props) => {
  const accessObject = (obj: any, value: any) => {
    if (value.split(".").length === 2) {
      if (!value.split(".")[0] && !value.split(".")[1]) {
        return "";
      } else {
        return obj[value.split(".")[0]][value.split(".")[1]];
      }
    }

    if (value.split(".").length === 3) {
      if (
        !value.split(".")[0] &&
        !value.split(".")[1] &&
        !value.split(".")[2]
      ) {
        return "";
      } else {
        return obj[value.split(".")[0]][value.split(".")[1]][
          value.split(".")[2]
        ];
      }
    }

    return "";
  };

  return (
    <div className={styles.table_container}>
      <table className={styles.table} style={{ overflow: "auto" }}>
        <thead style={{ zIndex: 100 }}>
          <tr>
            {props.head.map((val, index) => {
              return (
                <th align="center" key={index}>
                  {val.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.body.map((val, index) => {
            return (
              <tr key={index}>
                {props?.number && (
                  <td
                    onClick={() => {
                      if (props?.onRowClick) {
                        props.onRowClick(val);
                      }
                    }}
                    align="center"
                    style={
                      props?.onRowClick
                        ? { cursor: "pointer" }
                        : { cursor: "default" }
                    }
                  >
                    {/* {props?.page ? props?.page * 15 + val.title : index + 1}. */}
                    {props?.page
                      ? (props?.page - 1) * 15 + index + 1
                      : index + 1}
                    .
                  </td>
                )}
                {props.body_keys.map((valll: any, index) => {
                  return (
                    <td
                      key={index}
                      onClick={() => {
                        if (props?.onRowClick) {
                          props.onRowClick(val);
                        }
                      }}
                      align="center"
                      style={
                        props?.onRowClick
                          ? { cursor: "pointer" }
                          : { cursor: "default" }
                      }
                      onClickCapture={(event) => {
                        if (valll === "action") {
                          event.stopPropagation();
                          props.action_handler(val._id);
                        }
                      }}
                    >
                      {valll.indexOf("action") !== -1 ? (
                        <div
                          onClick={(event: any) => {
                            event.stopPropagation();
                            console.log(event);
                            console.log(event.target.dataset.name);
                            // props.onActionClick(
                            //   event.target.dataset.name,
                            //   val._id
                            // );
                          }}
                          style={{
                            // border: "1px solid black",
                            width: "fit-content",
                            display: "flex",
                          }}
                        >
                          <HandleActionValue
                            val={valll}
                            data={val}
                            index={index}
                            onActionClick={props.onActionClick}
                          />
                        </div>
                      ) : valll.includes("createdAt") ? (
                        val[valll].split("T")[0].split("-").reverse().join("-")
                      ) : valll === "remarks" ? (
                        // HandleRemarksPart(val)
                        <HandleRemarksPart
                          data={val}
                          onRemarksSave={props.onRemarksSave}
                        />
                      ) : valll.indexOf("status") !== -1 &&
                        valll.indexOf("dropdown") !== -1 ? (
                        <select
                          value={
                            val.status || val.joiningStatus || val.activeStatus
                          }
                          style={{ padding: "5px" }}
                          onChange={(event: any) => {
                            props.handleDropdownUpdate(val, event);
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {val.status && (
                            <>
                              <option value={"lead"}>Lead</option>
                              <option value={"open"}>Open</option>
                              <option value={"closed"}>Closed</option>
                            </>
                          )}

                          {val.activeStatus && (
                            <>
                              <option value={"active"}>Active</option>
                              <option value={"inactive"}>inActive</option>
                              <option value={"draft"}>Draft</option>
                            </>
                          )}

                          {val.joiningStatus && (
                            <>
                              <option value={"applied"}>Applied</option>
                              <option value={"interviewing"}>
                                Interviewing
                              </option>
                              <option value={"rejected"}>Rejected</option>
                              <option value={"accepted"}>Accepted</option>
                            </>
                          )}
                        </select>
                      ) : valll.indexOf("status") !== -1 &&
                        val.status === "live" ? (
                        <p style={{ fontWeight: 500, color: "green" }}>Live</p>
                      ) : val[valll] === true ? (
                        "True"
                      ) : val[valll] === false ? (
                        "False"
                      ) : valll.split(".").length > 1 ? (
                        accessObject(val, valll)
                      ) : (
                        val[valll]
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
