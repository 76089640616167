import React, { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import ContentHead from "../../../components/Content/ContentHead";
import { USER_DETAIL_TITLE } from "../../../utils/constants";
import ContentBody from "../../../components/Content/ContentBody";
import callApi from "../../../api/callApi";
import { useNavigate, useParams } from "react-router-dom";

const UserDetails = () => {
  const [data, setData]: any = useState();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);

    callApi({
      url: "user/get-user-by-id/" + params.id,
      method: "get",
      data: {},
      header: false,
    })
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        </>
      ) : (
        <Content>
          <ContentHead
            title={data ? data?.name : USER_DETAIL_TITLE}
            showBtn1={true}
            btn1Name="btn1"
            btn1Text="Back"
            btn1type="light_grey"
            onClickHandler={() => {
              navigate("/");
            }}
          />
          <ContentBody>
            <div style={{ paddingInline: "50px", paddingBottom: "100px" }}>
              <div
                style={{
                  height: "300px",
                  width: "300px",
                  borderRadius: "100%",
                }}
              >
                <img
                  src={data?.avatar}
                  alt="profile"
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "100%",
                    objectFit: "cover",
                    border: "2px solid lightgray",
                    objectPosition: "center center",
                  }}
                />
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(1,1fr)",
                  paddingInline: "30px ",
                  paddingTop: "50px",
                  gap: "20px",
                }}
              >
                <div>
                  <span style={{ fontWeight: "600" }}>Name : </span>
                  <span style={{ color: "gray" }}>{data.name}</span>
                </div>
                <div>
                  <span style={{ fontWeight: "600" }}>City : </span>
                  <span style={{ color: "gray" }}>{data.city}</span>
                </div>
                <div>
                  <span style={{ fontWeight: "600" }}>Phone : </span>
                  <span style={{ color: "gray" }}>{data.mobile}</span>
                </div>

                <div>
                  <span style={{ fontWeight: "600" }}>Email : </span>
                  <span style={{ color: "gray" }}>{data.name}</span>
                </div>
                <div>
                  <span style={{ fontWeight: "600" }}>Description : </span>
                  <span style={{ color: "gray" }}>{data.description}</span>
                </div>
                <div>
                  <span style={{ fontWeight: "600" }}>Profile Type : </span>
                  <span style={{ color: "gray" }}>{data.name}</span>
                </div>
                <div>
                  <span style={{ fontWeight: "600" }}>Starting Price : </span>
                  <span style={{ color: "gray" }}>{data.startingPrice[0]}</span>
                </div>
                <div>
                  <span style={{ fontWeight: "600" }}>Profile Completed :</span>
                  <span style={{ color: "gray" }}>{data.startingPrice[0]}</span>
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3,1fr)",
                    width: "100%",
                    gap: "20px",
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      Geners :
                    </span>
                    <ul
                      style={{
                        listStyle: "none",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      {data.genres.map((val: any, index: any) => {
                        return (
                          <li
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            <div>
                              <span>Genre : </span>
                              <span style={{ color: "gray" }}>{val.genre}</span>
                            </div>
                            <div>
                              <span>Sub Genre : </span>
                              <span style={{ color: "gray" }}>
                                {val.subGenre}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div>
                    <span
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      Services :
                    </span>
                    <ul
                      style={{
                        listStyle: "none",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      {data.services.map((val: any, index: any) => {
                        return (
                          <li
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            <div>
                              <span>Category : </span>
                              <span style={{ color: "gray" }}>
                                {val.category}
                              </span>
                            </div>
                            <div>
                              <span>Sub Category : </span>
                              <span style={{ color: "gray" }}>
                                {val.subCategory}
                              </span>
                            </div>
                            <div>
                              <span>Service Starging Price : </span>
                              <span style={{ color: "gray" }}>
                                {val.serviceStargingPrice}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div>
                    <span
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      Gear Highlight :
                    </span>
                    <ul
                      style={{
                        listStyle: "none",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      {data.gearHighLights.map((val: any, index: any) => {
                        return (
                          <li
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            <div>
                              <span>gear : </span>
                              <span style={{ color: "gray" }}>{val.gear}</span>
                            </div>
                            <div>
                              <span>Details : </span>
                              <span style={{ color: "gray" }}>
                                {val.gearHighLights}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3,1fr)",
                    width: "100%",
                    gap: "20px",
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      Terms :
                    </span>
                    <ul
                      style={{
                        listStyle: "none",
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      {data.terms.map((val: any, index: any) => {
                        return (
                          <li
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0px",
                            }}
                          >
                            <div>
                              <span style={{ color: "gray" }}>{val}</span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div>
                    <span
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      Social Links :
                    </span>
                    <ul
                      style={{
                        listStyle: "none",
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      {data.socialMedia.map((val: any, index: any) => {
                        return (
                          <li
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0px",
                            }}
                          >
                            <div>
                              <span style={{ color: "gray" }}>
                                <a href={val.link}>{val.plat}</a>
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div></div>
                </div>
              </div>
            </div>
          </ContentBody>
        </Content>
      )}
    </>
  );
};

export default UserDetails;
