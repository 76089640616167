import {
  faAddressBook,
  faBriefcase,
  faMusic,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  CONTACT_PATH,
  CONTACT_TITLE,
  JOB_PATH,
  JOB_TITLE,
  LYRICS_DETAILS_PATH,
  LYRICS_DETAILS_TITLE,
  LYRICS_PATH,
  LYRICS_TITLE,
  USER_DETAIL_PATH,
  USER_DETAIL_TITLE,
  USER_PATH,
  USER_TITLE,
} from "./constants";
import User from "../app/pages/user";
import Contacts from "../app/pages/contacts";
import Jobs from "../app/pages/jobs";
import Lyrics from "../app/pages/lyrics";
import LyricsDetails from "../app/pages/lyrics/details";
import UserDetails from "../app/pages/user/details";

export const sideBar = [
  {
    title: USER_TITLE,
    path: USER_PATH,
    icon: faUsers,
    element: User,
    show: true,
  },
  {
    title: USER_DETAIL_TITLE,
    path: USER_DETAIL_PATH,
    element: UserDetails,
    show: false,
  },
  {
    title: JOB_TITLE,
    path: JOB_PATH,
    icon: faBriefcase,
    element: Jobs,
    show: true,
  },
  {
    title: CONTACT_TITLE,
    path: CONTACT_PATH,
    icon: faAddressBook,
    element: Contacts,
    show: true,
  },
  {
    title: LYRICS_TITLE,
    path: LYRICS_PATH,
    icon: faMusic,
    element: Lyrics,
    show: true,
  },
  {
    title: LYRICS_DETAILS_TITLE,
    path: LYRICS_DETAILS_PATH,
    element: LyricsDetails,
    show: false,
  },
];

export const user_table_head = [
  { title: "S.NO" },
  { title: "Name" },
  { title: "Email" },
  { title: "Profile Completed" },
  { title: "Type" },
  { title: "" },
];
export const user_keys = [
  "name",
  "email",
  "isProfileCompleted",
  "isMusician",
  "action:delete",
];

export const job_table_head = [
  { title: "S.NO" },
  { title: "Title" },
  { title: "Posted by" },
  { title: "Status" },
  { title: "" },
];
export const job_keys = [
  "jobTitle",
  "jobPostedBy.name",
  "status",
  "action:delete",
];

export const contact_table_head = [
  { title: "S.NO" },
  { title: "Name" },
  { title: "Email" },
  { title: "Title" },
  { title: "Message" },
];
export const contact_keys = ["name", "email", "title", "message"];

export const lyrics_table_head = [
  { title: "S.NO" },
  { title: "Song Name" },
  { title: "Artist" },
  { title: "Status" },
  { title: "Actions" },
];
export const lyrics_keys = [
  "songName",
  "artistName",
  "status",
  "action:delete",
];
