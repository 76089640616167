import React from "react";
import Content from "../../../components/Content/Content";
import ContentHead from "../../../components/Content/ContentHead";
import ContentBody from "../../../components/Content/ContentBody";
import { JOB_TITLE, LYRICS_TITLE } from "../../../utils/constants";
import Table from "../../../components/Table";
import {
  job_keys,
  job_table_head,
  lyrics_table_head,
} from "../../../utils/data";
import callApi from "../../../api/callApi";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";

const Jobs = () => {
  const [loading, setLoading] = React.useState(true);
  const [jobs, setJobs] = React.useState<any[]>([]);
  const [modal, setModal] = React.useState(false);
  const [inFocus, setInFocus] = React.useState("");

  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    callApi({
      url: "jobs/get-jobs",
      method: "GET",
      data: {},
      header: false,
    })
      .then((res) => {
        setJobs(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setModal(false);

    toast.promise(
      new Promise((resolve, reject) => {
        callApi({
          url: "admin/delete-job/" + inFocus,
          method: "DELETE",
          data: {},
          header: true,
        })
          .then((res: any) => {
            getData();
            resolve(true);
          })
          .catch((err: any) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted successfully",
        error: "Error while deleting",
      }
    );
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => {
          setModal(false);
          setInFocus("");
        }}
        onBtn2Click={handleDelete}
        state={modal}
      />
      <Content>
        <ContentHead title={JOB_TITLE} />
        <ContentBody style={{ padding: 0 }}>
          <>
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: "rgba(0,0,0,.3)",
                  zIndex: 100000,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span className="loader"></span>
              </div>
            )}
            <Table
              head={job_table_head}
              body={[...jobs]}
              body_keys={job_keys}
              number={true}
              onActionClick={(val: string, id: string) => {
                setModal(true);
                setInFocus(id);
              }}
              onRowClick={(val: any) => {}}
            />
          </>
        </ContentBody>
      </Content>
    </>
  );
};

export default Jobs;
