import { useContext, useState } from "react";

import { ReactComponent as Logo } from "../../../assets/logo.svg";
import styles from "../../../styles/login.module.css";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import callApi from "../../../api/callApi";

const Login = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  // this is used for handle sign-in
  const handleOtp = () => {
    if (!email) {
      toast.error("Username is required.");
    } else if (!otp) {
      toast.error("Password is required.");
    } else {
      setLoading(true);

      callApi({
        url: "user/login",
        method: "post",
        data: {
          email: email,
          password: otp,
        },
        header: false,
      })
        .then((res: any) => {
          console.log(res.data.refresh_token);
          localStorage.setItem("udukku_session_token", res.data.refresh_token);
          window.location.reload();
          setLoading(false);
        })
        .catch(() => {
          toast.error("Email and Password are invalid.");
          setLoading(false);
        });
    }
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.main}`}>
        <div className={`${styles.slide}`}>
          <div>
            {/* modal container 1 */}
            <div
              className={styles.modal_container}
              style={{ background: "#f6540e" }}
            >
              <div className={styles.modal}>
                <h1>Admin Login</h1>
                <div className={styles.fields_container}>
                  <div className={styles.label_and_field}>
                    <span>Username</span>
                    <input
                      type="text"
                      placeholder="Enter Username"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <div style={{ marginTop: "20px" }}>
                      <span>Password</span>
                      <input
                        type="password"
                        placeholder="Enter Your Password"
                        value={otp}
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                      />
                    </div>
                    <button onClick={handleOtp} disabled={loading}>
                      {loading ? (
                        <span className="loader"></span>
                      ) : (
                        <>Sign in</>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* right section */}
      <div
        className={styles.carousel}
        style={{
          width: "100%",
          height: "100%",
          //   backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            background: "rgb(8, 32, 50)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Logo style={{ height: "100px", width: "auto" }} />
        </div>
      </div>
    </div>
  );
};

export default Login;
