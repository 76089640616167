import React from "react";
import AppLayout from "../components/AppLayout";
import { Outlet } from "react-router-dom";
import Login from "./pages/login";

const App = () => {
  return !localStorage.getItem("udukku_session_token") ? (
    <Login />
  ) : (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default App;
