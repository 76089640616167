import React from "react";
import Content from "../../../components/Content/Content";
import ContentHead from "../../../components/Content/ContentHead";
import ContentBody from "../../../components/Content/ContentBody";
import { CONTACT_TITLE } from "../../../utils/constants";
import Table from "../../../components/Table";
import { contact_keys, contact_table_head } from "../../../utils/data";
import callApi from "../../../api/callApi";
import { toast } from "react-hot-toast";

const Contacts = () => {
  const [loading, setLoading] = React.useState(true);
  const [contacts, setContacts]: any = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [inFocus, setInFocus] = React.useState("");

  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    callApi({
      url: "admin/get-all-queries",
      method: "GET",
      data: {},
      header: true,
    })
      .then((res) => {
        setContacts([...res.data.queries]);

        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setModal(false);

    toast.promise(
      new Promise((resolve, reject) => {
        callApi({
          url: "admin/delete-user/" + inFocus,
          method: "DELETE",
          data: {},
          header: true,
        })
          .then((res: any) => {
            getData();
            resolve(true);
          })
          .catch((err: any) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted successfully",
        error: "Error while deleting",
      }
    );
  };

  return (
    <Content>
      <ContentHead title={CONTACT_TITLE} />
      <ContentBody style={{ padding: 0 }}>
        <>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: "rgba(0,0,0,.3)",
                zIndex: 100000,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className="loader"></span>
            </div>
          )}
          <Table
            head={contact_table_head}
            body={contacts}
            body_keys={contact_keys}
            number={true}
            onActionClick={() => {}}
          />
        </>
      </ContentBody>
    </Content>
  );
};

export default Contacts;
