// core lib or third party lib
import React from "react";
import ReactDOM from "react-dom/client";

// third party components
import { Toaster } from "react-hot-toast";

// local components
import App from "./app/app";

// styles
import "./styles/global.css";
import Router from "./app/router";

// assets

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <Toaster />
    <Router />
  </>
);
