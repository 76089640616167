import axios from "axios";

type Props = {
  url: string;
  method: string;
  data: any;
  header: boolean;
};

const baseUrl = "https://backend.udukku.com/";

const callApi = (props: Props) => {
  return axios({
    url: baseUrl + props.url,
    method: props.method,
    data: props.data,
    headers: props.header
      ? {
          Authorization: localStorage.getItem("udukku_session_token"),
        }
      : {},
  });
};

export default callApi;
