import React from "react";
import Content from "../../../components/Content/Content";
import ContentHead from "../../../components/Content/ContentHead";
import { LYRICS_DETAILS_TITLE } from "../../../utils/constants";
import ContentBody from "../../../components/Content/ContentBody";
import callApi from "../../../api/callApi";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const LyricsDetails = () => {
  const [loading, setLoading] = React.useState(true);
  const [lyric, setLyric]: any = React.useState({});
  const navigate = useNavigate();

  const params = useParams();

  React.useEffect(() => {
    callApi({
      url: "lyrics/get-lyrics-by-id/" + params.id,
      method: "GET",
      data: {},
      header: false,
    })
      .then((res) => {
        setLyric(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const verified = () => {
    callApi({
      url: "lyrics/update-lyrics",
      method: "PATCH",
      data: {
        lyricsId: lyric._id,
        status: "verified",
      },
      header: false,
    })
      .then((res) => {
        navigate("/lyrics");
        toast.success("updated Successfully!");
      })
      .catch((err) => {
        toast.error("Error While updating Status");
      });
  };

  const reject = () => {
    callApi({
      url: "lyrics/update-lyrics",
      method: "PATCH",
      data: {
        lyricsId: lyric._id,
        status: "rejected",
      },
      header: false,
    })
      .then((res) => {
        navigate("/lyrics");
        toast.success("updated Successfully!");
      })
      .catch((err) => {
        toast.error("Error While updating Status");
      });
  };

  const handleClick = (e: any) => {
    if (e.target.name === "btn1") {
      verified();
    } else if (e.target.name === "btn2") {
      reject();
    } else if (e.target.name === "btn3") {
      navigate("/lyrics");
    }
  };

  return (
    <Content>
      <ContentHead
        title={
          lyric?.songName ? lyric.songName + " - by " + lyric.artistName : ""
        }
        showBtn1={true}
        btn1Text="Approve"
        btn1Name="btn1"
        btn1type="filled"
        showBtn2={true}
        btn2Text="Reject"
        btn2Name="btn2"
        btn2type="outlined_del"
        showBtn3={true}
        btn3Text="Back"
        btn3Name="btn3"
        btn3type="light_grey"
        onClickHandler={handleClick}
      />
      <ContentBody style={{ padding: "30px 100px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "50px",
            overflow: "hidden",
          }}
        >
          <div>
            <img
              src={lyric.coverPhoto}
              alt="cover"
              style={{
                aspectRatio: "16/9",
                width: "100%",
                borderRadius: "20px",
              }}
            />
          </div>
          <div>
            <h3>About Artist</h3>
            <p>{lyric.aboutArtist}</p>
          </div>

          <div>
            <h3>Lyrics</h3>
            <p>{lyric.lyrics}</p>
          </div>

          <div>
            <h3>People Involved</h3>
            <ul>
              {lyric?.peopleInvolved &&
                lyric?.peopleInvolved.map((val: any, index: any) => {
                  return (
                    <li key={index}>
                      {val.peopleInvolved} ( <span>{val.role}</span> )
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </ContentBody>
    </Content>
  );
};

export default LyricsDetails;
