import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../styles/button.module.css";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

type props = {
  text: string | undefined;
  type: "outlined" | "filled" | "outlined_del" | "light_grey" | undefined;
  style?: any;
  name: string | undefined;
  clickHandler: any;
  isIcon: any;
  iconType?: any;
};

const Button = (props: props) => {
  return (
    <button
      name={props.name}
      className={`${styles.button_container} ${
        props?.type ? styles[props.type] : ""
      }`}
      style={props?.style}
      onClick={(event: React.SyntheticEvent) => {
        props.clickHandler(event);
      }}
    >
      {props?.isIcon && (
        <FontAwesomeIcon
          name={props.name}
          icon={faPlus}
          style={{ paddingRight: "5px" }}
        />
      )}
      {props.text}
    </button>
  );
};

export default Button;
