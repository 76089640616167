import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { sideBar } from "../utils/data";
import App from "./app";

const Router = () => {
  const children: any = [];
  sideBar.map((val) => {
    children.push({
      path: val.path,
      element: <val.element />,
    });
  });

  const routes = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <div>404</div>,
      children: children,
    },
  ]);

  return <RouterProvider router={routes} />;
};

export default Router;
