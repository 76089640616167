import React, { useState } from "react";
import Content from "../../../components/Content/Content";
import ContentHead from "../../../components/Content/ContentHead";
import ContentBody from "../../../components/Content/ContentBody";
import { LYRICS_TITLE } from "../../../utils/constants";
import Table from "../../../components/Table";
import { lyrics_keys, lyrics_table_head } from "../../../utils/data";
import callApi from "../../../api/callApi";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/Modal";
import { toast } from "react-hot-toast";

const Lyrics = () => {
  const [modal, setModal] = useState(false);
  const [inFocus, setInFocus] = useState("");
  const [loading, setLoading] = React.useState(true);
  const [lyrics, setLyrics] = React.useState([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    callApi({
      url: "lyrics/get-lyrics",
      method: "POST",
      data: {
        page: 1,
        count: 10000,
      },
      header: false,
    })
      .then((res) => {
        setLyrics(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  const handledelete = () => {
    setModal(false);

    toast.promise(
      new Promise((resolve, reject) => {
        callApi({
          url: "lyrics/delete-lyrics/" + inFocus,
          method: "DELETE",
          data: {},
          header: true,
        })
          .then((res: any) => {
            getData();
            resolve(true);
          })
          .catch((err: any) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted successfully",
        error: "Error while deleting",
      }
    );
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => {
          setModal(false);
          setInFocus("");
        }}
        onBtn2Click={handledelete}
        state={modal}
      />
      <Content>
        <ContentHead title={LYRICS_TITLE} />
        <ContentBody style={{ padding: 0 }}>
          <>
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: "rgba(0,0,0,.3)",
                  zIndex: 100000,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span className="loader"></span>
              </div>
            )}
            <Table
              head={lyrics_table_head}
              body={[...lyrics]}
              body_keys={lyrics_keys}
              number={true}
              onActionClick={(val: string, id: string) => {
                setModal(true);
                setInFocus(id);
              }}
              onRowClick={(val: any) => {
                navigate(`/lyrics/${val._id}`);
              }}
            />
          </>
        </ContentBody>
      </Content>
    </>
  );
};

export default Lyrics;
